import React from 'react';
import { Select } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  FileJpgOutlined
} from '@ant-design/icons';

import './index.css';
const { Option } = Select;

const SelectKeyWord = props => {
  return (
    <Select
      loading={props.isloadKeyWords}
      disabled={props.isloadKeyWords}
      value={props.isloadKeyWords ? 'Select a keyword' : null}
      showSearch
      style={{ width: 300, margin: '20px' }}
      placeholder="Select a keyword"
      optionFilterProp="children"
      onChange={props.handleKeywordChange}
      dropdownMatchSelectWidth={false}
      filterOption={(input, option) =>
        option.children.props.children[1].props.children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.keyWordList.length >= 1 && !props.isloadKeyWords
        ? props.keyWordList.map((keyWord, i) => (
            <Option key={keyWord + i} value={keyWord}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  <a
                    href={
                      'http://nodejs.ineedwebsite.com.au:5000/screens/' +
                      props.currentSite
                        .replace(/https*:\/\//, '')
                        .replace(/\/$/, '') +
                      '-' +
                      keyWord +
                      '.jpg'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FileJpgOutlined style={{ marginRight: '5px' }} />
                  </a>
                </span>

                <div
                  style={{
                    width: '80%',
                    overflow: 'hidden',
                    marginRight: '5px'
                  }}
                >
                  {keyWord}
                </div>
                <div
                  refs="ss"
                  style={{
                    width: '60px',
                    textAlign: 'right'
                  }}
                >
                  <span style={{ color: '#1890ff' }}>
                    {props.rankGoogleEndPeriod[i]}
                  </span>
                  {props.rankGoogleEndPeriod[i] ===
                  props.rankGoogleStartPeriod[i] ? (
                    <span>&nbsp;</span>
                  ) : props.rankGoogleEndPeriod[i] <
                    (props.rankGoogleStartPeriod[i]
                      ? props.rankGoogleStartPeriod[i]
                      : 31) ? (
                    <CaretUpOutlined style={{ color: '#52c41a' }} />
                  ) : (
                    <CaretDownOutlined style={{ color: '#ea0c0c' }} />
                  )}
                  <span style={{ color: '#434348' }}>
                    {props.rankGoogleMapsEndPeriod[i]}
                  </span>
                  {props.rankGoogleMapsEndPeriod[i] ===
                  props.rankGoogleMapsStartPeriod[i] ? (
                    <span>&nbsp;</span>
                  ) : props.rankGoogleMapsEndPeriod[i] <
                    (props.rankGoogleMapsStartPeriod[i]
                      ? props.rankGoogleMapsStartPeriod[i]
                      : 31) ? (
                    <CaretUpOutlined style={{ color: '#52c41a' }} />
                  ) : (
                    <CaretDownOutlined style={{ color: '#ea0c0c' }} />
                  )}
                </div>
              </div>
            </Option>
          ))
        : null}
    </Select>
  );
};

export default SelectKeyWord;
