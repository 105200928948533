import React from 'react';
import Highlighter from 'react-highlight-words';
import { Table, Input, Button, Tag } from 'antd';
import {
  SearchOutlined,
  CheckSquareOutlined,
  FilterOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';

import './index.css';

class TaskTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: ''
    };
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  hendelFilterClick = el => {
    this.props.hendleClick(el);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  render() {
    const columns = [
      {
        title: (
          <div id="filter">
            <FilterOutlined
              style={{
                color: this.props.isActiveFilterTask ? '#2693ff' : null
              }}
            />
          </div>
        ),
        render: () => ' ',
        dataIndex: 'TASK_ID',
        key: 'TASK_ID',
        width: '2%'
      },
      {
        title: 'Date Due',
        dataIndex: 'DUE_DATE',
        key: 'DUE_DATE',
        width: '8%',
        ...this.getColumnSearchProps('DUE_DATE'),
        render: date => <p>{date}</p>,
        sorter: (a, b) => {
          let _a = moment(a.DUE_DATE, 'DD/MM/YY');
          let _b = moment(b.DUE_DATE, 'DD/MM/YY');
          return _a._d - _b._d;
        },
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Category',
        dataIndex: 'CATEGORY_NAME',
        key: 'CATEGORY_NAME',
        width: '20%',
        ...this.getColumnSearchProps('CATEGORY_NAME'),
        render: text => (
          <Tag color="geekblue" key={text}>
            {text}
          </Tag>
        ),
        sorter: (a, b) =>
          a.CATEGORY_NAME.toString().localeCompare(b.CATEGORY_NAME),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Activity Name',
        dataIndex: 'TITLE',
        key: 'TITLE',
        width: '30%',
        ...this.getColumnSearchProps('TITLE'),
        render: (text, record) => (
          <a
            href={
              'https://crm.na1.insightly.com/details/Task/' + record.TASK_ID
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.TITLE}
          </a>
        ),
        sorter: (a, b) => a.TITLE.toString().localeCompare(b.TITLE),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Details',
        dataIndex: 'DETAILS',
        key: 'DETAILS',
        width: '45%',
        ...this.getColumnSearchProps('DETAILS'),
        render: text => <p>{text ? text.replace(/<[^>]+>/g, '') : '-'}</p>,
        sorter: (a, b) => a.DETAILS.toString().localeCompare(b.DETAILS),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Status',
        dataIndex: 'STATUS',
        key: 'STATUS',
        width: '10%',
        render: text => (
          <p>
            {text === 'COMPLETED' ? (
              <CheckSquareOutlined style={{ color: '#7bc786' }} />
            ) : (
              '-'
            )}
          </p>
        ),
        sorter: (a, b) => a.STATUS.toString().localeCompare(b.STATUS),
        sortDirections: ['descend', 'ascend']
      }
    ];

    return (
      <Table
        onHeaderRow={column => {
          return {
            onClick: e => {
              let filter = e.target.closest('div').id;
              if (filter === 'filter') {
                this.hendelFilterClick();
              }
            }
          };
        }}
        columns={columns}
        dataSource={this.props.dataSource}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 480 }}
        size="small"
      />
    );
  }
}

export default TaskTable;
