import React from 'react';
import { DatePicker } from 'antd';

import './index.css';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YY';

const DateRangePicker = props => {
  let { startDate, endDate } = props;

  return (
    <RangePicker
      style={{ width: 300, margin: '40px 20px' }}
      //defaultValue={[startDate, endDate]}
      allowClear={false}
      value={[startDate, endDate]}
      format={dateFormat}
      onChange={props.handleDateRangePickerChange}
    />
  );
};

export default DateRangePicker;
