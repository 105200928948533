import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import './index.css';

const Loader = props => {
  return <HighchartsReact options={props.options} highcharts={Highcharts} />;
};

export default Loader;
