import React from 'react';
import { Spin, Alert } from 'antd';

import './index.css';

const Loader = props => {
  return (
    <Spin tip="loading data...">
      <Alert
        message={props.message}
        description={props.description}
        type="info"
        style={{ height: '500px' }}
      />
    </Spin>
  );
};

export default Loader;
