import React from 'react';
import { Select } from 'antd';
import './index.css';
const { Option } = Select;

const SelectSite = props => {
  // console.log(props);
  return (
    <Select
      showSearch
      style={{ width: 300, margin: '20px' }}
      placeholder="Select a site"
      optionFilterProp="children"
      onChange={props.handleSiteChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.siteList.length >= 1
        ? props.siteList.map((site, i) => (
            <Option key={site + i} value={site}>
              {site}
            </Option>
          ))
        : null}
    </Select>
  );
};

export default SelectSite;
