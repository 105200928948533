import React, { Component } from 'react';
import './App.css';
import TaskTable from './components/TaskTable/TaskTable';

import { Row, Col } from 'antd';

import moment from 'moment';
import 'antd/dist/antd.css';

import Loader from './components/Loader/Loader';
import DateRangePicker from './components/DateRangePicker/DateRangePicker';
import SelectSite from './components/SelectSite/SelectSite';
import SelectKeyWord from './components/SelectKeyWord/SelectKeyWord';
import Chart from './components/Chart/Chart';
import ActiveTask from './components/ActiveTask/ActiveTask';

const dateFormat = 'DD/MM/YY';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      API_URL:
        'https://script.google.com/macros/s/AKfycbw_zFIajC-B1EMzz1nse5vB-w1UJNrnyp278xeImFX0nMs0zn8p/exec?',
      siteList: [],
      keyWordList: [],
      keyWordListData: [],
      tasksList: [],
      tasksListFiltered: [],
      taskCategories: [],
      startDate: moment('01/01/18', dateFormat),
      endDate: moment(),
      currentSite: '',
      currentKeyWord: '',
      isloadKeyWords: false,
      isloadTasks: false,
      rankGoogleStartPeriod: [],
      rankGoogleMapsStartPeriod: [],
      rankGoogleEndPeriod: [],
      rankGoogleMapsEndPeriod: [],
      xAxisCategories: [],
      xAxisCategoriesFiltered: [],
      series: [],
      seriesFilteres: [],
      isActiveFilterTask: false,
      options: {
        chart: {
          height: 500,
          events: { click: this.hendleChartClick.bind(this) }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },

        subtitle: {
          text: ''
        },
        tooltip: {
          shared: true
          // formatter: function() {
          //    console.log(this.state);
          //    return this.x + '</b> is <b>' + this.y + '</b>';
          // }
        },

        yAxis: {
          title: {
            text: 'Rank'
          },
          reversed: true,
          allowDecimals: false,
          ceiling: 31,
          softMin: 0,
          minorTickInterval: 'auto'
        },

        xAxis: {
          categories: []
        },

        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 50,
          y: 10,
          floating: true
        },

        series: [
          {
            name: 'Google',
            data: [null]
          },
          {
            name: 'Google Maps',
            data: [null]
          }
        ],
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            }
          },
          series: {
            point: {
              events: {
                mouseOver: this.setHoverData.bind(this),
                click: this.hendleChartClick.bind(this)
              }
            }
          }
        },

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
                }
              }
            }
          ]
        }
      }
    };
  }

  componentDidMount() {
    fetch(this.state.API_URL + 'route=getSitesList')
      .then(response => response.json())
      .then(data => {
        this.setState({ siteList: data.sort(), isloadTasks: true });
        // console.log(this.state);
      });
  }

  handleSiteChange = value => {
    let options = { ...this.state.options };
    options.subtitle.text = '';
    options.title.text = `Site: ${value}`;
    options.series = [];
    this.setState({
      options,
      currentSite: value,
      currentKeyWord: '',
      keyWordList: [],
      tasksList: [],
      tasksListFiltered: [],
      isActiveFilterTask: '',
      hoverData: '',
      isloadKeyWords: true
    });

    fetch(
      this.state.API_URL + 'route=getKeywordAndTaskListData&sheetName=' + value
    )
      .then(response => response.json())
      .then(data => {
        let { taskListData, keyWordListData } = data;
        //устанавливаем дату начала и конца периода для конкретного сайта
        let startDate = keyWordListData[1][1];
        let endDate = keyWordListData[1][keyWordListData[1].length - 3];

        let isRegExpStartDate = startDate.match(/\d*-\d*-\d*/);
        let isRegExpEndDate = endDate.match(/\d*-\d*-\d*/);
        if (isRegExpStartDate) {
          startDate = moment(isRegExpStartDate[0], 'YYYY-MM-DD')
            .add(1, 'days')
            .format(dateFormat); //возвращает не ту дату
          startDate = moment(startDate, dateFormat);
        }
        if (isRegExpEndDate) {
          endDate = moment(isRegExpEndDate[0], 'YYYY-MM-DD')
            .add(1, 'days')
            .format(dateFormat); //возвращает не ту дату
          endDate = moment(endDate, dateFormat);
        }

        let keyWordListDataWithoutHeader = keyWordListData.slice(3);

        let keyWordList = keyWordListDataWithoutHeader.map(cell => cell[0]);
        let rankGoogleStartPeriod = keyWordListDataWithoutHeader.map(
          cell => (cell[1] === 'undefined' ? 0 : cell[1]) //меняем текст undefined на 0
        );
        let rankGoogleMapsStartPeriod = keyWordListDataWithoutHeader.map(
          cell => (cell[2] === 'undefined' ? 0 : cell[2]) //меняем текст undefined на 0
        );
        let rankGoogleEndPeriod = keyWordListDataWithoutHeader.map(
          cell =>
            cell[cell.length - 3] === 'undefined' ? 0 : cell[cell.length - 3] //меняем текст undefined на 0
        );
        let rankGoogleMapsEndPeriod = keyWordListDataWithoutHeader.map(
          cell =>
            cell[cell.length - 2] === 'undefined' ? 0 : cell[cell.length - 2] //меняем текст undefined на 0
        );

        //проставляем категории для тасклиста
        let tasksList = taskListData.tasksList.map(task => {
          let CATEGORY_NAME = taskListData.taskCategories.filter(category => {
            return category.CATEGORY_ID === task.CATEGORY_ID;
          });
          try {
            CATEGORY_NAME = CATEGORY_NAME[0].CATEGORY_NAME;
          } catch (error) {}
          task.CATEGORY_NAME = CATEGORY_NAME;
          task.key = task.TASK_ID;
          return task;
        });
        // таски для инсайтли
        tasksList = tasksList.filter(task => {
          task.DETAILS = task.DETAILS || ' ';
          task.DUE_DATE = moment(task.DUE_DATE, 'YYYY-MM-DD').format(
            'DD/MM/YY'
          );
          return (
            task.TITLE && task.CATEGORY_NAME && task.DUE_DATE !== 'Invalid date'
          );
        });

        let tasksListFiltered = tasksList.filter(task => {
          let date = moment(task.DUE_DATE, dateFormat);
          return startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date);
        });

        this.setState({
          startDate,
          endDate,
          currentSite: value,
          keyWordList,
          isloadKeyWords: false,
          keyWordListData,
          currentKeyWord: '',
          options,
          tasksList,
          tasksListFiltered,
          taskCategories: taskListData.taskCategories,
          rankGoogleStartPeriod,
          rankGoogleMapsStartPeriod,
          rankGoogleEndPeriod,
          rankGoogleMapsEndPeriod
        });
      });
  };

  handleKeywordChange = value => {
    // console.log(this.state);
    // console.log(`selected ${value}`);
    let options = { ...this.state.options };
    options.subtitle.text = `Keyword: ${value}`;
    let dates = this.state.keyWordListData[1];
    let keyWordList = this.state.keyWordListData.map(cell => cell[0]);
    let keyWordIndex = keyWordList.indexOf(value);
    let xAxisCategories = [];
    let series = [
      {
        name: 'Google',
        data: []
      },
      {
        name: 'Google Maps',
        data: []
      }
    ];
    dates.forEach((dataCell, i) => {
      let isRegExpDate = dataCell.match(/\d*-\d*-\d*/);

      if (isRegExpDate) {
        let date = moment(isRegExpDate[0], 'YYYY-MM-DD')
          .add(1, 'days') //возвращает не ту дату
          .format(dateFormat);
        xAxisCategories.push(date);
        let googleRank = this.state.keyWordListData[keyWordIndex][i];
        if (!googleRank) {
          googleRank = 31;
        }
        series[0].data.push(googleRank);
        let googleMapsRank = this.state.keyWordListData[keyWordIndex][i + 1];
        if (!googleMapsRank) {
          googleMapsRank = 31;
        }
        series[1].data.push(googleMapsRank);
      }
    });

    options.series = series;
    options.xAxis.categories = xAxisCategories;
    // console.log(series);
    // console.log(xAxisCategories);
    this.setState({ options, xAxisCategories, series, currentKeyWord: value });
  };
  handleDateRangePickerChange = (date, dateString) => {
    let startDate = dateString[0] ? moment(dateString[0], dateFormat) : null;
    let endDate = dateString[1] ? moment(dateString[1], dateFormat) : null;
    if ((startDate, endDate)) {
      this.setState({ startDate, endDate });
    }

    //фильтраци tasksListFiltered
    let tasksListFiltered = this.state.tasksList.filter(task => {
      let date = moment(task.DUE_DATE, dateFormat);
      return startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date);
    });
    this.setState({ tasksListFiltered });

    // фильтрация xAxisCategories: [],
    // обнуляем
    let prevOptions = { ...this.state.options };
    prevOptions.series = [...this.state.series];
    // console.log(this.state.options);
    prevOptions.xAxis.categories = [...this.state.xAxisCategories];
    // console.log(prevOptions);
    // console.log(this.state);
    this.setState({ options: prevOptions });

    let xAxisCategories = this.state.xAxisCategories;
    let xAxisCategoriesFiltered = [];
    let googleYAxis = [];
    let googleMapsYAxis = [];
    let series = [...this.state.options.series];
    xAxisCategories.forEach((xAxisData, i) => {
      let date = moment(xAxisData, dateFormat);
      if (startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date)) {
        xAxisCategoriesFiltered.push(xAxisData);
        googleYAxis.push(series[0].data[i]);
        googleMapsYAxis.push(series[1].data[i]);
      }
    });
    series[0].data = googleYAxis;
    series[1].data = googleMapsYAxis;
    // console.log(series);
    // console.log(xAxisCategoriesFiltered);
    // console.log(this.state);
    let options = { ...this.state.options };
    options.series = series;
    options.xAxis.categories = xAxisCategoriesFiltered;
    this.setState({ options });
  };

  setHoverData = e => {
    //console.log('setHoverData');
    //console.log(e);
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });

    // e.xAxis[0].axis.toPixels(e.xAxis[0].value),
    //   e.yAxis[0].axis.toPixels(e.yAxis[0].value);
  };
  hendleChartClick = event => {
    // console.log('hendleChartClick');
    // console.log(e.target);
    // console.log(event);
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: event.target.category });
  };
  hendleClick = event => {
    let isActiveFilter = !this.state.isActiveFilterTask;
    this.setState({ isActiveFilterTask: isActiveFilter });
    if (this.state.isActiveFilterTask) {
      let tasksListFiltered = this.state.tasksList.filter(task => {
        let date = moment(task.DUE_DATE, dateFormat);
        return (
          this.state.startDate.isSameOrBefore(date) &&
          this.state.endDate.isSameOrAfter(date)
        );
      });
      this.setState({ tasksListFiltered });
      return;
    }
    let tasksListFiltered = this.state.tasksList.filter(task => {
      let date = moment(task.DUE_DATE, dateFormat);
      let date2 = moment(this.state.hoverData, dateFormat);
      return date.isSame(date2, 'week');
    });
    this.setState({ tasksListFiltered });
  };
  render() {
    return (
      <div className="App">
        <a href="/">
          <img src="logo.png" width="250px" alt="logo"></img>
        </a>

        <DateRangePicker
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          handleDateRangePickerChange={this.handleDateRangePickerChange}
        />
        <SelectSite
          siteList={this.state.siteList}
          handleSiteChange={this.handleSiteChange}
        />
        <SelectKeyWord
          keyWordList={this.state.keyWordList}
          isloadKeyWords={this.state.isloadKeyWords}
          currentSite={this.state.currentSite}
          rankGoogleStartPeriod={this.state.rankGoogleStartPeriod}
          rankGoogleEndPeriod={this.state.rankGoogleEndPeriod}
          rankGoogleMapsStartPeriod={this.state.rankGoogleMapsStartPeriod}
          rankGoogleMapsEndPeriod={this.state.rankGoogleMapsEndPeriod}
          handleKeywordChange={this.handleKeywordChange}
        />
        {this.state.isloadTasks && this.state.currentSite ? (
          <>
            <Row style={{ border: '1px solid #d9d9d9' }}>
              <Col span={22}>
                {this.state.keyWordList.length >= 1 ? (
                  <Chart options={this.state.options} />
                ) : (
                  <Loader message description />
                )}
              </Col>
              <Col span={2}>
                <ActiveTask
                  xAxisDate={this.state.hoverData}
                  //dataSource={this.state.tasksList}
                  allTaskCount={this.state.tasksListFiltered.length}
                  dataSource={this.state.tasksListFiltered}
                  isActiveFilterTask={this.state.isActiveFilterTask}
                  hendleClick={this.hendleClick}
                />
              </Col>
            </Row>
            <TaskTable
              isActiveFilterTask={this.state.isActiveFilterTask}
              //dataSource={this.state.tasksList}
              dataSource={this.state.tasksListFiltered}
              hendleClick={this.hendleClick}
            />
          </>
        ) : (
          false
        )}
      </div>
    );
  }
}
export default App;
