import React from 'react';

import { FilterOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { List } from 'antd';
import moment from 'moment';
import './index.css';

const dateFormat = 'DD/MM/YY';

const ActiveTask = props => {
  //console.log(props);
  let { xAxisDate, dataSource, allTaskCount } = props;
  const FiltedataSource = dataSource.filter(row => {
    let { DUE_DATE } = row;
    if (xAxisDate && DUE_DATE) {
      let date1 = moment(DUE_DATE, dateFormat);
      let date2 = moment(xAxisDate, dateFormat);
      return date1.isSame(date2, 'week');
    }
    return false;
  });

  const hendelFilterClick = () => {
    props.hendleClick();
  };
  return (
    <List
      header={
        <div>
          <FilterOutlined
            style={{
              marginRight: '5px',
              color: props.isActiveFilterTask ? '#2693ff' : null
            }}
            onClick={hendelFilterClick}
          />
          {xAxisDate ? xAxisDate : moment(moment()).format(dateFormat)}
          <p style={{ fontSize: '11px' }}>
            Tasks: {FiltedataSource.length} All Tasks: {allTaskCount}
          </p>
        </div>
      }
      size="small"
      style={{ height: '500px', overflowY: 'scroll' }}
      bordered
      dataSource={FiltedataSource}
      renderItem={item => (
        <List.Item
          style={{
            fontSize: '12px',
            margin: '0 auto',
            padding: '0px',
            textalign: 'center',
            display: 'block',
            color: '#1890ff'
          }}
        >
          {item.TITLE.length < 20 ? (
            <a
              href={
                'https://crm.na1.insightly.com/details/Task/' + item.TASK_ID
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.DUE_DATE}
              <br /> {item.TITLE}
            </a>
          ) : (
            <a
              href={
                'https://crm.na1.insightly.com/details/Task/' + item.TASK_ID
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.DUE_DATE}
              <br /> {item.TITLE.substr(0, 20) + '...'}
            </a>
          )}
        </List.Item>
      )}
    />
  );
};

export default ActiveTask;
